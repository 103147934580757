export default {
  "account": {
    "essential_information": "Basic Information",
    "corporate_name": "Company Name",
    "company_id": "Company ID",
    "company_characteristic": "公司标识",
    "registration_number": "Registration Number",
    "mobile_phone": "Mobile Phone",
    "secure_mailbox": "Security Mailbox",
    "account_security": "Account Security",
    "change_password": "Change Password",
    "original_password": "Original Password",
    "new_password": "New Password",
    "enter_the_new_password_again": "Enter Again",
    "the_passwords_entered_twice_do_not_match": "New Password Inconsistent",
    "password_modified_successfully": "Password Changed Successfully",
    "please_input": "Enter",
    "forget_password": "Forgot password",
    "enter_verification_code": "Enter verification code",
    "cell_phone_number": "Mobile phone number",
    "login_account": "Login",
    "register_new_users": "Register",
    "create_account": "Create account",
    "registration_country": "Country",
    "phone_number": "Phone",
    "phont_number_is_in_the_wrong_format": "Incorrect format for phone number",
    "currently_only_supports_mainland_china": "Currently support Mainland China only",
    "confirm_password": "Confirm password",
    "validation_code": "Verification Code",
    "send_sms": "Sending SMS",
    "sms_validation_code": "SMS code",
    "complete_the_info_of_enterprise": "Complete company information",
    "data_is_under_review": "Data auditing",
    "data_has_been_reviewed": "Data audit passed",
    "data_review_failed": "Data audit failed",
    "pending_tip": "Data auditing takes about 1-2 working days",
    "corporate_name_tip": "Please enter the company full name that complys with Business License",
    "business_license": "Business License",
    "account_manager": "Account Owner",
    "start_use": "Start tour",
    "modify": "Modify",
    "registration_number_format_error": "Incorrect format for Registration Number",
    "mobile_phone_number_is_not_registered": "This number has not been registered",
    "SMS_sent_successfully": "SMS sent",
    "SMS_sending_failed": "SMS sending failed",
    "set_new_password": "Setting new password",
    "enter_the_password_again": "Enter again",
    "the_two_passwords_are_inconsistent": "New password inconsistent",
    "account_registration_successful": "Account registration successful!",
    "timezone": "Timezone",
    "update_timezone": "Modify Timezone",
    "choose_timezone": "Select Timezone",
    "service_authority": "服务授权",
    "through_authorization_the_application_will_gain_access_to_your_API": "通过授权,该应用将获得您的API权限",
    "after_authorization_you_will_be_granted_the_following_permissions": "授权后，将获得以下权限授权",
    "refuse": "拒绝",
    "agree_authorize": "同意授权",
    "refund": "Refund",
    "role": "Role",
    "account_name": "Account Name",
    "country": "Country",
    "registration_date": "Registration Time",
    "last_login_time": "Latest Login Time",
    "account_status": "Account Status",
    "details": "Details",
    "serial_number": "Serial Number",
    "login_time": "登录时间",
    "user_roles": "User Roles",
    "main_account": "主账号",
    "nick_name": "Nickname",
    "remove_user_tip": "你确认要移除该账户?",
    "remove_user": "移除账号",
    "password": "密码",
    "password_default_tip": "默认密码为手机号后6位,登录后请重置密码"
  }
}